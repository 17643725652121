.icon-preview-button{
    display: flex !important;
    gap: 0px !important;
    justify-content: center !important;
    align-items: center !important;
}

.icon-work-area{
    margin-top: 24px;
}

.notification-template-icon-uploader{
    border: 2px dashed #ccc;
    padding: 20px;
    text-align:center;
    cursor: pointer;
    border-radius: 10px;
}

.notification-template-icon-uploader:hover{
    border: 2px dashed #7586e0 !important;
    cursor: pointer;
    color: #7586e0 !important;
  
}

.notification-template-icon-uploader-header{
    font-size: 20px;
    font-weight: 500;
}

.notification-template-icon-uploader-text{
    color: #a1a1a1;
}

.notification-preview-modal-image{
    width: 100%;
}

.notification-preview-modal-image-wrapper{
    max-height: 60vh;
    overflow-y: auto ;
}

.otification-preview-modal-image-title{
    height: 25px;
}