.step-table-title{
    font-size: 17px !important;
    font-weight: 500 !important;
}


.step-table-createdAt{
    color: #a0a0a0;
    font-weight: 500;
}

.step-table-condition-tag{
    padding: 5px 15px;
    font-weight: 500;
    font-size: 16px;
}

.step-table-no-condition-tag{
    padding: 5px 15px;
    font-weight: bold;
    font-size: 16px;
    color: #e74a3b;
    border: 2px solid #e74a3b;
    background-color: #e74a3b44;
}

.followup-step-table-options-icon{
    font-size: 20px !important;
}
.followup-step-table-options-icon-text{
    font-size: 16px;
    margin:0px;
    padding: 0px;
}