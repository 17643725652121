.planed-right-side{
    border-left: 1.5px solid #f0f0f0;
    height: 100%;
    padding: 24px;
    background-color: rgb(251, 240, 225);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

}

.planed-icon{
    font-size: 32px !important;
}

.planed-calendar-header{
    padding:15px !important;
    border-bottom: 1px solid #f0f0f0;
}

.planer-card{
    cursor: pointer;
    transition: .1s all ease;
}

.planer-card:hover{
    outline: 5px solid #7586e0;
    outline-offset: -5px;
}

.planer-move-button-icons{
    font-size: 22px;
}

.ant-picker-calendar-date-value{
    font-weight: 500 !important;
}

.planer-task-name{
    font-weight: 600 !important;
}

.planer-task-type{
    padding: 0px 10px ;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    background-color: #f1f1f1;
    border-radius: 15px;
}

.planer-overdue-task{
    padding: 0px 10px ;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    background-color: #e74a3b;
    border-radius: 15px;
    font-weight: 600;
    color: white;
}

.panler-task-owner{
    padding: 0px 10px ;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    background-color: #7586e0;
    border-radius: 15px;
    font-weight: 600;
    color: white;
}

.planer-task-card{
    transition: .1s all ease;
    cursor: pointer;
}

.planer-task-card:hover{
    outline: 5px solid #7586e0;
    outline-offset: -5px;
}