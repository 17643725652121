.design-template-preview{
    margin-top: 24px;
    width: 100%;
    background-color: #f5f5f5;
    height: 500px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.design-template-preview:hover .design-template-preview-overlay{
    display: flex;
}

.design-template-preview-overlay{
    position: absolute;
    background-color: #ffffffaa;
    border-radius: 10px;
    width: 340px;
    height: 460px;
    display: none;
}
.desing-frame-template{
    overflow-x: hidden !important;
    border: none;
    border-radius: 20px;
    width: 100%;
    height: 460px;
    padding: 0;
    margin: 0;
    display: block;
}

.desing-template-preview{
    height: 480px;
    width: 360px;
    border: 10px solid #cfcfcf;
    border-radius: 25px;
}

.html-preview-box{
    max-height: 60vh;
    overflow-y: auto ;
    padding:10px;
}


.html-preview-box-wrapper:hover{
    border:1px solid gray;
}

.flex-preview-button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.desing-options-dropdown{
    font-size: 16px;
    margin: 0px;
}