.notification-list-title{
    font-size: 18px !important;
    font-weight: 500 !important;
}


.notification-list-created-at{
    color: #a0a0a0;
    font-weight: 500;
}

.notification-saved{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:6px;
    color: #1cc88a;
    background-color: #1cc88a33;
    padding: 5px 15px;
    font-size: 16px !important;
    border-radius: 10px;
    font-weight: 500;
}

.notification-draft{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:6px;
    color: #a0a0a0;
    font-size: 16px !important;
    font-weight: 500;
    background-color: #a0a0a033; 
    border-radius: 10px;
    padding: 5px 15px;
}