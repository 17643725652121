.navToggle {
  width: auto;
  background-color: #f4f0ff;
  border-radius: 10px;
  width: fit-content;
  padding: 5px 10px;
}

.navToggle a {
  padding: 5px 10px;
}

.navToggle a.active {
  background-color: red;
}

.dashboardHeader button {
  min-width: 100px;
  height: unset !important;
}

.mainKanbanContainer {
  display: flex;
  flex: 1;
  min-height: 500px;
  overflow: auto;
}

.kanbanColumn {
  flex: 1;
  overflow: auto;
  padding-inline: 10px;
  height: 85vh !important;
  min-width: 300px;
}
.sortableContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}
.kanbanColumn:not(:first-child) {
    border-left-width: 1px;
    border-left-style: solid;
    
}
.kanbanColumn > div {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}
.kanbanCard {
   font-size: 14px;
    width: 100%;
    margin: 5px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.05);
  background-color: white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.kanbanCard.dragging {
  cursor: grabbing !important;
}

.styledBadge {
  position: relative;
  isolation: isolate;
  font-size: 0.7rem;
  padding: 5px 10px;
  width: fit-content;
  overflow: hidden;
  border-radius: 10px;
}
 .styledBadge span {
  font-weight: bold;
}
.kanbanCard h3 {
  margin: 0;
  margin-top: 10px;
  font-weight: 500;
  font-size: 0.9rem;
  color: #94929c;
}

 .styledBadge::before {
  content: "";
  background-color: currentColor;
  position: absolute;
  inset: 0;
  opacity: 0.1;
  z-index: -1;
}

.kanbanCard .styledBadge::before {
  content: "";
  background-color: currentColor;
  position: absolute;
  inset: 0;
  opacity: 0.1;
  z-index: -1;
}
.kanbanCard .cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.kanbanCard .cardHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.kanbanCard .cardFooter > span {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #94929c;
}

.kanbanCardDrag {
  background-color: #f4f0ff;
  opacity: 0.5;
  cursor: grabbing;
}

.kanbanCardRect {
  border-radius: 10px;
  min-height: 200px;
  padding: 10px;

}

.cardStatusBreadCrumbsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.cardStatusBreadCrumbsWrapper > div {
  clip-path:polygon(97% 0, 100% 50%, 97% 100%, 0% 100%, 3% 50%, 0% 0%);
  padding: 5px 15px;
  background-color: #f5f5f5;
  color: #696969;
  font-weight: 600;
  min-width: 190px;
  max-width: 190px;
  font-size: 1rem;
  white-space: nowrap;
  flex: 1;
  cursor: pointer;
text-align: center;
}

.addNoteQuill{
  min-height: 200px !important;
  border:1px solid #f0f0f0 ;
  border-radius: 15px;
}

.cardStatusBreadCrumbsWrapper > div.active { 
  background-color: #722ed1;
  color: white;
}

.cardStatusBreadCrumbsWrapper.won > div.active { 
  background-color: #0b996f;
}

.cardStatusBreadCrumbsWrapper.lost > div.active {
background-color: #ff4a4a;
 }

.ant-drawer-body {
  display: flex;
  flex-direction: column;
}

.ant-drawer-body  > form {
  flex:1;
  display: flex;
  flex-direction: column;
}

.formFooter {
  margin-top: auto;
  position: sticky;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  bottom: 0;
} 


.activityCollapse .ant-collapse-header {
  align-items: center !important;
}


.dealDetailsWrapper{
  max-width: 1300px;
  width: 100%;
  margin-inline: auto;

}

.dealDetailsHeader {
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right: 0;
  border-left: 0;
  padding: 15px 10px;
  border-color: #d3d3d3;
}

.rdw-editor-toolbar{
  padding-top: 10px !important ;
  padding-left: 10px !important ;
}

.rdw-editor-main{
  padding-left: 10px !important;
}