.contact-button, .contact-button-whatsapp {
    display: flex;
    flex-direction: column;
    height:60px;
    width: 60px;
    gap: 0px;
    font-weight: 600;
}

.contact-button-whatsapp{
    width: 100px;
}

.contact-details-data{
    font-size: 20px;
    text-align: center;
    width: 100%;
    font-weight: 600;
}