.comunication-dot{
    width:25px;
    height: 25px;
    border-radius:25px;
    border: 5px solid #aaa;
}
.comunication-timeline{
    margin: 12px;
}

.communication-date-filter-select{
    width: 200px;
}

.communication-date-filter-button{
    display: flex ;
    align-items: center;
    justify-content: space-between;
    width: 200px;
}

.communication-date-filter-border-right{
    width: 250px;
}

.ant-popover-inner {
    margin: 0px !important;
    padding:0px !important;
}

.collapse-div-communications{
    border-bottom: 2px solid #f0f0f0;
    border-left: 1.5px solid #f0f0f0;
    margin-left: -21px;
    margin-top: -5px;
}

.communication-error-wrapper{
    background-color: #e74a3b;
    border-radius:15px;
    text-align: center;
    width: 100px;
}
.communication-wrapper-text{
    color: white !important;
    font-weight: bold;
}

.communication-success-wrapper{
    background-color: #1cc88a;
    border-radius:15px;
    text-align: center;
    width: 100px;
}

.time-text-communication{
    font-size: 12px !important;
    font-weight: 500;
}

.communication-seperator{
    width: 103%;
    padding: 0px;
    margin:0px ;
    margin-left: -17px;

    border: 1px solid transparent; /* Set the border width */
    border-image: repeating-linear-gradient(90deg, #e0e0e0 0, #e0e0e0 10px, transparent 10px, transparent 20px);
    border-image-slice: 20;
}

.communication-footer{
    margin-top: 12px;
    background-color: #f0f0f0; 
    padding: 10px;
    border-radius: 35px;
    
    }