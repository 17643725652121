* {
  font-family: "DM Sans" !important;
  text-rendering: optimizelegibility !important;
}

body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
}

.user-edit-profile-image {
  width: 160px;
  height: 160px;
  border-radius: 160px;
  background-size: cover !important;
  background-position: center !important;
}

.fab-container {
  bottom: 2vh !important;
  right: 2vh !important;
}

.fab-container.disabled {
  opacity: 0.8;
  filter: grayscale();
  pointer-events: none;
}

.c-b {
  position: relative;
  overflow: hidden;
  border-top-width: 25px !important;
}

.image-container {
  background-size: cover;
  background-clip: content-box !important;
  position: absolute;
  width: 100%;
  top: 0px;
  overflow: hidden !important;
  left: 0px;
  filter: blur(200px);
  opacity: 0.1;
  height: 100%;
}



.selected-card {
  border: 2px solid #4e73dfaa;
  z-index: 1;
  box-shadow: 0 0 5px #4e73dfaa;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.w-100 {
  width: 100% !important;
}

.card-image {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 200px;
  border-bottom: none;
  border: 1px solid #f0f0f0;

}

.card-image-preview {
  opacity: 0;
  transition: .1s all ease-in;
}

.card-image:hover .card-image-preview {
  opacity: 1;
}

.campaign-tag {
  width: 100px;
  text-align: center;
}



.disabled-card {
  cursor: not-allowed !important;

}

.ant-table-cell {
  background-color: transparent !important;
}

.hoverable-title:hover {
  text-decoration: underline;
}

.shadow {
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, .08) !important;
}

/*.ant-card, .ant-collapse{
  box-shadow: 14px 17px 40px 4px rgba(112,144,176,.08) !important;
}*/

.text-light {
  color: #a3aed0 !important;
}

.text-small{
  font-size: 12px !important;
  font-weight: bold;
  color: #afafaf !important;
}

.text-semi-bold{
  font-weight: 400 !important;
}

.ant-segmented {
  border-radius: 15px;
}

.ant-checkbox-inner {
  border-radius: 5px !important;
}

.shadowless {
  box-shadow: none !important;
}

.audience-tag {
  width: 90px;
  text-align: center;
}

.flex-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.flex-button-start{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2px;
}

.small-radius {
  border-radius: 7px;
}

.success-response,
.error-response {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.success-response {
  background-color: #1cc88a;
}

.error-response {
  background-color: #e74a3b;
}

.need-help {
  color: white;
  background: linear-gradient(140deg, #5F84Ff 20%, #422afb 100%);
  margin-top: 50px;
  transition: .1s all ease;
}

.not-round {
  border-radius: 0px !important;
}

.blue-gradient {
  background: rgb(150, 127, 251);
  background: linear-gradient(159deg, rgba(150, 127, 251, 1) 0%, rgba(75, 41, 224, 1) 100%);
}

.success-gradient {
  background: rgb(150, 127, 251);
  background: linear-gradient(159deg, #11998e 0%, #38ef7d 100%);
}

/*.primary-gradient-background{
  background: #4A25E1;
  background: linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%);
}
*/

.transparent-input .ant-input {
  background: transparent !important;
}

.transparent-input .ant-input-lg {
  background: red !important;
}

.transparent-button {
  background-color: transparent !important;
}

.ReactModal__Overlay ReactModal__Overlay--after-open {
  position: fixed;
}

.ReactModalPortal {
  position: fixed;
}

.selected-item {
  background-color: #1cc88a;
}

.text-smaller {
  font-size: 12px !important;
}

.icon-flex {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 6px;
}

.header-less thead {
  display: none;
}

.text-center {
  text-align: center !important;
}

.ant-modal-content-no-padding>.ant-modal-content {
  padding: 0px !important;
}

.chat-container {
  position: relative;
  width: 100% !important;
  height: calc(100% - 50px);
  overflow-y: auto;
  padding: 0px 10px;
}

.message-box {
  position: relative;
  display: flex;
  width: 100% !important;
  margin: 5px 0;
}

.message-box p {
  position: relative;
  right: 0;
  text-align: right;
  max-width: 100%;
  width: 85%;
  padding: 12px;
  background: #dcf8c6;
  border-radius: 10px;
  font-size: 0.9em;
  box-shadow: 0 0 5px rgba(0, 0, 0, .2);
}

.message-box p span {
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  opacity: 0.5;
}

.my-message {
  justify-content: flex-end;
}

.friend-message p {
  background: #fff;
}

.friend-message {
  justify-content: flex-start;

}

.chat-container .my-message i {
  color: yellow;
}

.message-box.friend-message p::before {
  content: '';
  position: absolute;
  top: 0;
  left: -12px;
  width: 20px;
  height: 20px;
  background: linear-gradient(225deg, #fff 0%, #fff 50%, transparent 50%, transparent);
}

.whatsapp-link {
  color: #00a5f4 !important;
  font-size: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  opacity: 1 !important;
}

.whatsapp-link-icon {
  color: #3299c7 !important;
  opacity: 1;
}

.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  z-index: 100000 !important;
}

.ant-float-btn {
  width: auto !important;
}

.rdw-editor-toolbar {
  border: none !important;
  border-bottom: 1px solid #f0f0f0 !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.demo-editor {
  margin: 10px;
}

.demo-wrapper,
.rdw-editor-wrapper {
  min-height: 650px !important;
}


.ant-table,
.ant-table-empty {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.ant-typography-link:hover {
  text-decoration: underline !important;
}

th.ant-table-cell {
  color: #a3aed0 !important;
  letter-spacing: 1.3px;
  text-transform: uppercase !important;
}

.transparent-card {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.white-link-button {
  color: white;
  font-size: 16px;
}

.ant-typography-link {
  cursor: pointer !important;
}

/*
#webpack-dev-server-client-overlay-div {
  display: none !important;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}


.ant-table-row{
  transition: .2s all ease-in;
}
.ant-table-row:hover{
  box-shadow: 0px 10px 10px rgba(0,0,0,.2);
}*/

.ant-menu-sub {
  background-color: transparent !important;
}

.ant-layout-footer {
  background-color: transparent !important;
}



.no-body-card .ant-card-head {
  border-bottom: none !important;
}

.bold-button {
  font-weight: 500 !important;
}

.end-to-end-header .ant-card-head {
  padding-right: 10px !important;
  padding-left: 20px !important;
  margin: 0px !important;
}

/*
.ant-radio-inner::after{
  background: white !important;
}*/

.little-transparent-input .ant-select-selector {
  background-color: transparent !important;
}

.transparent-select-label {
  color: #bfc3dd;
}

.center-number-input .ant-input-number-input {
  text-align: center !important;
}

.ant-menu-title-content {
  font-size: 16px !important;
}

.ant-menu-item,
.ant-menu-submenu {
  font-weight: 500;
}

.ant-menu-item-selected {
  font-weight: 700 !important;
  background-color: transparent !important;
}

.ant-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-menu-item-selected .ant-menu-title-content::after {
  content: " ";
  display: flex;
  height: 30px;
  width: 3px;
  border-radius: 10px;
  background-color: #422afb;
}

.ant-menu-item-selected .menu-icon {
  color: #422afb !important;
}

.ant-card {
  /*box-shadow:rgba(0, 0, 0, 0.08) 14px 17px 40px 4px !important;
  box-shadow: rgba(112, 144, 176, 0.08) 14px 17px 40px 4px !important;
  box-shadow:  20px 20px 60px #bebebe,
  -20px -20px 60px #ffffff;
  box-shadow:  -16px 16px 32px #d9d9d9,
  16px -16px 32px #ffffff;
  */
  box-shadow: rgba(0, 0, 0, 0.04) 14px 17px 40px 4px;
}

.small-font {
  font-size: 12px;
  margin-top: -5px;
}

.ant-select-selection-item {
  border-radius: 10px !important;
}

.ant-table-placeholder {
  background-color: transparent !important;
}

.ant-pagination-item {
  border-radius: 100% !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.no-head-space .ant-card-head {
  padding: 0px;
  margin: 0px;
}

.black-color-tag {
  color: #1b254b !important;
  font-weight: bold !important;
}

.white-color-tag {
  color: #fff !important;
  font-weight: bold !important;
}

.duplicate-button {
  opacity: 0;
  transition: .2s all ease-in;
}

.dublicate-button-card:hover .duplicate-button {
  opacity: 1 !important;
}

.zero-margin-padding>.ant-card-body {
  padding: 0px;
  margin: 0px;
}

.zero-margin-padding-head>.ant-card-head {
  padding: 5px !important;
  margin: 0px !important;
  min-height: 0px !important;
}

.quality-tag {
  padding: 5px 25px;
  font-size: 18px !important;
  border-width: 3px !important;
}

.ant-color-picker-color-block-inner {
  border-radius: 10px !important;
}

.flex-avatar {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}


.create-email-button {
  width: 200px !important;
  border-radius: 50px !important;
}

::-webkit-scrollbar {
  width: 2.5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.ant-segmented-item-selected {
  border-radius: 15px !important;
}


.smart-text-buttons{
  width: 100%;
  font-weight: 500;
}

.ai-button {
	display: flex;
	justify-content: center;
	align-items: center;
  gap: 3px;
	color: white;
	font-weight: 500;
	width:130px;
  border-radius: 10px;
	height: 25px;
  background: white;
  cursor: pointer;
  background: rgb(3,0,30);
  transition: .5s all ease;
  background: #0fbc92;
 /*background: rgb(3,0,30);
  background: linear-gradient(120deg, rgba(3,0,30,1) 0%, rgba(115,3,192,1) 53%, rgba(255,0,128,1) 100%);  
*/}

.ai-button:hover{
  background-color: #0fbc9288;
  /*background: linear-gradient(120deg, rgba(3,0,30,0.7) 0%, rgba(115,3,192,0.7) 53%, rgba(255,0,128,0.7) 100%);  */
}

.underline-link{
  text-decoration: underline !important;
  cursor: pointer !important;
}

.underline-link:hover{
  font-weight: 600 !important;
  text-decoration: none !important;
}

.order-by-select > .ant-select-selector{
  background-color: #eaeaf8 !important;
  color: #0c0b0b !important;
  border: none !important;
  border-radius: 50px !important;
}

.order-by-select-darker > .ant-select-selector{
  background-color: #eaeaf8 !important;
  color: #0c0b0b !important;
  border: none !important;
  border-radius: 50px !important;
}

.dates-filter-select{
  background-color: #eaeaf8 !important;
  color: #0c0b0b !important;
  border: none !important;
  flex-grow: 1 !important;
   border-radius:50px !important
}

.column-header {
  text-transform: uppercase;
  color: #242424;
  font-weight: 600;
}

.new-buttons{
  border-radius: 17px !important;
}

.filter-group-item{
  background-color: #eaeaf8;
  padding: 5px 5px 5px 15px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border-radius: 50px;
  font-weight: 500;
  color: #0c0b0b !important;
}

.h-100{
  height: 100%;
}

.active-menu{
  border: none;
  border-radius: 7px;
  background-color: #7586e0;
  }

.h-100{
  height: 100%;
}

.center-table-column{
  display: flex;
  align-items: center;
  justify-content: start;
}

.full-height-column {
  height: 100% !important;
}

.hoverable-card{
  transition: .2s all ease;
}
.hoverable-card:hover{
  z-index: 999; 
  border: 1px solid #00000033;
  box-shadow: 0px 10px 10px rgba(0,0,0,.15);
  cursor: pointer;
}

.upload-card{
  height: 200px;
  border: 2px dashed #a1a1a1 !important;
}

.upload-card:hover{
  border: 2px dashed #7586e0 !important;
  cursor: pointer;
  color: #7586e0 !important;

} 


.preview-table{
 width: 100%;
 border: 1px solid #a0a0a0;
 border-collapse: collapse;
}

.preview-table th{
  background-color:#f0f0f0 ;
  text-transform: uppercase;
  text-align: left !important;
}

.no-bold{
  font-weight: 400 !important;
}

.zero-margin-padding{
  padding:0px !important;
  margin: 0px !important ;
}

.white{
  color: white !important;
}

.center-text{
  text-align: center !important;
}

.tox-statusbar__branding{
  display: none !important;
}

.drop-down-text{
  font-size: 18px !important;
  font-weight: 500;
  padding: 0px;
  margin:0px ;
}

.drop-down-icon{
  font-size: 16px !important;
}

.drop-down-divider{
  background-color:#e1e1e1;
  width: 120%;
  height: 2px;
  border-radius:5px;
  margin: -10px;
}

.disabled{
  opacity: 0.8;
  pointer-events: none;
  cursor:not-allowed  !important;  
  background-color: rgba(0,0,0,.05);
}

.ant-drawer-header{
  background-color: rgb(117, 134, 224) !important;
  color: white !important;
  border-top-left-radius: 10px !important;

}

.ant-drawer-content-wrapper, .ant-drawer-content{
  border-top-left-radius: 10px !important;
}

.hidden-form-item{
  visibility:hidden !important;
  display: none !important;
}


.zero-margin-padding-children, .zero-margin-padding-children > *{
  padding: 0px !important;
  margin:0px !important ;
}

.large-font{
  font-size: 18px !important;
}

.user-data-span{
  background-color: red !important;
}

.user-data-span-fullname{
  background-color: red !important;
}

.hard-shadow{
  box-shadow: 0px 20px 50px rgba(0,0,0,.1);
}

.min-max-height{
  min-height: 100vh !important;
}

.white{
  color: white !important;
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(1.1);
  }

  100% {
      transform: scale(1);
  }
}

.pulse {
  animation: pulse 4s infinite;
}

.ant-list-bordered {
  border: 1px solid #f0f0f0 !important;
}

.semi-bold{
  font-weight: 500 !important;
}

.small-flex-button{
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.ant-tabs-tab-btn{
  font-weight: 500 !important;
}

.margin-top-24{
  margin-top: 24px !important;
}

.negative-top{
  margin-top: -20px !important;
}

.negative-top-12{
  margin-top: -6px !important;
  padding: 0px;
  margin-bottom: 0px;
}

.border-top{
  border-top: 1px solid #f0f0f0 !important;
  margin-left: -25px !important;
}

.margin-top-12{
  margin-top: 12px !important;
}


.success{
  color: #1cc88a;
}
.error{
  color: #e74a3b;
}

.large-font{
  font-size: 18px !important;
  font-weight: 600 !important;
}


.large-font-text{
  font-size: 18px !important;
  font-weight: 500 !important;
}

.gray-border {
  border-color: hsl(0, 0%, 89%) !important;
}

.gray-border-hoverable{
  border-color: hsl(0, 0%, 89%) !important;
  transition: .1s all ease-in;
}
.gray-border-hoverable:hover{
  box-shadow: 0px 5px 10px rgba(0,0,0,.15) !important;
  cursor: pointer;
}

.no-left-padding-margin{
  margin-left:0px !important ;
  padding-left:0px !important ;

}

.no-right-padding-margin{
  margin-right:0px !important ;
  padding-right:0px !important ;

}

.large-icon{
  font-size: 30px !important;
}

.ant-pagination-simple-pager{
  font-size: 18px !important;
  font-weight: 500 !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.simple-pagination-page{
  color: #422afb !important;
  font-weight:bold !important;
  font-size: 18px ;
  text-decoration: underline;
}

.light-error{
  background-color: rgba(255,0,0,.05);
  border: 1px solid rgba(255,0,0,.25) !important;
}

.light-success{
  background-color: rgba(0,255,0,.05);
  border: 1px solid rgba(0,255,0,.25) !important;
}

.all-stats-tag{
 font-size: 16px;
 font-weight: 600 !important; 
 padding: 0px 15px;
 border-width: 2px !important;
}

.default-stat-tag{
  background-color: hsl(0, 0%, 89%) !important;
}

.no-pointer-events{
  pointer-events: none !important;
}

.ant-notification-notice-progress{
  height: 5px !important;
}

.ant-notification-notice{
  border-radius: 100px !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;  
}

.div-card{
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
}

.margin-24{
  margin:24px !important;
}

.rhap_container{
  border-width: 0px !important;
  box-shadow: none !important;
}

.zero-bottom-margin{
  margin-bottom: 0px !important;
}

.font-size-26{
  font-size: 26px !important;
}