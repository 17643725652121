.home-follow-up-icon{
    font-size: 70px !important;
}


.home-follow-up{
    transition: .1s all ease;
    cursor: pointer;
}

.home-follow-up:hover{
    outline: 5px solid #7586e0;
    color: #7586e0 !important;
}