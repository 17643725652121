.BuyPhoneNumber-list-title{
    font-size: 17px !important;
    font-weight: 500 !important;
}

.BuyPhoneNumber-list-created-at{
    color: #a0a0a0;
    font-weight: 500;
}

.BuyPhoneNumber-type{
    padding: 2.5px 15px;
    background-color: #e1e1e1;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
}

.BuyPhoneNumber-price{
    color: #1cc88a;
    font-weight: 600;
    font-size: 22px;
}

.BuyPhoneNumber-text{
    width: 100%;
}

.BuyPhoneNumber-icon-dropdown{
    color: #afafaf;
}


.price-boxes{
    border: 2px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BuyPhoneNumber-table-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}