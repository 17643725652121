.agents-wrapper{
    background-color: #f4f7fe;
    border-radius: 15px;
    padding: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
    transition: .01s all ease;
    height: 40px;
}

.agents-wrapper:hover{
    outline: 3px solid #7586e0;
}

.agents-wrapper-text{
    font-weight: 600 !important;
}

.agent-name{
    font-weight: bold !important;
    font-size: 18px !important;
}

.agent-status{
    display: flex;
    align-items: center;
    justify-content: start;
    font-weight: bold;
}

.online-text{
    color: #1cc88a;
}
.offline-text{
    color: #e74a3b !important;
}

.agents-scroll-wrapper{
    max-height: 330px;
    overflow-y:auto ;
    max-width: 400px;
    width: 400px;
}