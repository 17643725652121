.dialer-wrapper{
    width: 200px;
}

.flex-end{
    display: flex;
    align-items: center; 
    justify-content: end;
}

.flex-center{
    display: flex;
    align-items: center; 
    justify-content:center;
}

.dialer-button{
    width: 60px;
    height: 60px;
    font-size: 18px;
    box-shadow: 0px 10px 10px rgba(0,0,0,.1) !important;
    border-width:0px;
    transition:.01s all ease ;
}

.dialer-button:hover{
    border:2px solid #7586e0 !important;
}

.dialer-number-preview{
    width: 100%;
    border: 0px solid #1b254b;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0px 10px 10px rgba(0,0,0,.1) !important;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialer-icon{
    font-size: 16px;
}

.call-button-wrapper{
    position: fixed;
    top: 85%;
    left: 25%;
    transform: translate(-50%);
}

.class-button-dialer{
    width: 70px;
    height: 70px;
    font-size: 30px;
    border-radius: 70px;
    background: #6fd474;
    box-shadow: 0px 10px 10px #6fd47461 !important;
    color: #fff;
}

.dialer-info-icon{
    font-size: 22px;
    color: gray;
}

.dialer-info-text{
    color: gray;
    font-size: 16px;
    font-weight: 500;
}

.phone-dialer-title{
    font-weight: 500 !important;
}

.no-round{
    border-radius: 0px !important ;
}

.recent-calls-wrapper{
    height: calc(100vh - 64px);
    max-height: calc(100vh - 64px);
    overflow-y:auto;
}