.segmented-icon{
    font-size: 16px;
}

.segmented-icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
}

.preview-test-preview{
    max-height: 450px;
    height: 450px;
    margin-top:24px ;
}


.preview-test-preview-phone{
    height: 430px;
    width: 340px;
    border: 10px solid #cfcfcf;
    border-radius: 25px;
}

.preview-test-preview-desktop{
    width: 97%;
    border: 10px solid #cfcfcf;
    border-radius: 25px;
    height: 430px;
}

.phone-frame-test{
    overflow-x: hidden !important;
    border: none;
    border-radius: 20px;
    width: 100%;
    height: 430px;
    padding: 0;
    margin: 0;
    display: block;
}

.preview-test-preview-background{
    background: #f7f7f7;
}

.create-preview-test-header{
    font-size: 18px;
    font-weight: bold;
}

.add-card{
    margin-top: 12px;
}

.add-card-operations{
    margin-top: 6px;
}