.iphone-wrapper{
    height: 159.9mm;
    width: 76.7mm;
    border-radius: 45px;
    border:3px solid #cfcfcf;
}

.iphone-inner-wrapper{
    margin: 2px ;
    border-radius: 40px;
    border:3px solid #cfcfcf;
    height: 99.3%;
}

.iphone-nav-row{
    height: 50px;
}
.dynamic-island-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.iphone-time-wrapper{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iphone-time{
   font-size: 16px;
   color: #cfcfcf;
   font-weight: bold;
}

.iphone-icon{
    color: #cfcfcf;
    font-size: 18px;
}
.dynamic-island{
    width: 100px;
    height: 30px;
    border-radius: 30px;
    background-color: #cfcfcf;
}

.iphone-icons-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    height: 50px;
    color: #cfcfcf;

}

.iphone-buttons-left{
    position: absolute;
    top: 40%;
    left: -1%;
    z-index: -1;
}

.iphone-small-button{
    width: 5px;
    background-color: #cfcfcf;
    height: 30px;
    border-radius: 10px;
}

.iphone-button{
    width: 5px;
    height: 50px;
    border-radius: 10px;
    background-color: #cfcfcf;
}

.iphone-button-large{
    width: 3px;
    height: 60px;
    border-radius: 10px;
    background-color: #cfcfcf;
}

.iphone-buttons-right{
    position: absolute;
    top: 50%;
    left: 100%;
    z-index: -1;
}