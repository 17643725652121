.keyboard-wrapper{
    background-color: #ececec;
}

.sms-phone-sms-wrapper{
    border-right: 1px solid hsl(0, 0%, 89%) !important;
    height: 90vh;
}

.sms-phone-sms-text-area{
    border-radius:0px !important ;
}

.sms-phone-tag{
    font-weight: bold !important;
    background: hsl(0, 0%, 89%) !important;
}

.border-right{
    border-right:1px solid hsl(0, 0%, 89%);
}

.sms-texts-wrapper{
    overflow-y: auto !important;
    flex: 1 !important;
}

.max-100-vh{
    max-height: 100vh !important;
}

.keyborad-input-space{
    flex: 0 250px 0;
    height: 255px;
}

.sms-phone-card{
    height: 66px !important;
}

.mt-10{
    margin-top: 24px;
}

.sms-flex-wrapper-your {
    max-width: 90% !important;
    border:none !important;
    margin-right: 6px;
}

.sms-flex-wrapper {
    margin-left: 6px;
    max-width: 90% !important;
    border:none !important;
    background-color: #e9e9eb;
}

.sms-text{
    font-size: 16px !important;
    font-weight: 500 !important;
}

.your-sms-text{
    color: #fff;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.your-sms{
    background-color: #37d95f;
    color: #000;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.recent-message-last-sms{
    color: #818181;
}

.contact-sms-phone-wrapper{
    border-radius: 0px !important;
    height: 65px;
}

.contact-name-sms{
    font-size: 18px;
    font-weight: 600;
}