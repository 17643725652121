.schedule-text{
    font-weight: 600;
    font-size: 18px;
}

.schedule-radio{
    font-size: 18px !important;
}

.schedule_for_later{
    font-weight: 500;
    font-size: 18px;
}
