.error-box{
    padding:10px;
    border-radius:10px ;
    background-color: #e74a3b33;
    border: 2px solid #e74a3b;
    margin-top: 24px;
}

.error-description{
    color: #e74a3b;
    font-size: 16px;
}

.error-tittle{
    font-size: 20px;
    font-weight: bold;
    color: #e74a3b;
}