.component-type-text{
    font-weight: 600;
    text-align: center;
    border: 2px solid #cacaca;
    padding: 5px;
    width: 120px;
    border-radius: 5px;
    color: #a1a1a1;
}

.create-component-type{
    padding:5px 10px ;
    border: 1px solid #cacaca;
    background-color: lightgray;
    border-radius: 5px;
    font-weight: 500;
}