.step-card{
    width: 750px;
    border-radius: 0px;
    border-color: hsl(0, 0%, 89%) !important;
}

.first-card{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.last-card{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.step-evaluation-box-default{
    width: 35px;
    height: 35px;
    border-radius: 35px;
    border: 10px solid #c1c1c1;
}
.step-evaluation-box-success{
    width: 25px;
    height: 25px;
    border-radius: 40px;
    background-color: #1cc88a;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.step-evaluation-box-error{
    width: 25px;
    height: 25px;
    border-radius: 40px;
    background-color: #e74a3b;
}

.no-shadow{
    box-shadow:none !important ;
}

.edit-icon{
    font-size: 20px !important;
}

.campaign-status-tag{
font-size: 18px !important;
    font-weight: 600 !important;
}

.active-step{
    z-index: 99;
    border-radius: 10px !important;
    border: 2px solid #e1e1e1;
    margin-top: -15px;
    margin-bottom: -15px;
    filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.05))
}

.thick-lable{
    font-weight: bold;
    font-size:16px;
}

.small-skeleton{
    width: 50px !important;
}

.w-300{
    width:165px;
    font-weight: 500;
}