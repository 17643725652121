.product-title{
    font-size: 18px !important;
    font-weight: 500 !important;
}

.product-dates{
    color: #a0a0a0;
    font-weight: 500;
}


.no-owners-tag{
    padding:2px 15px;
    font-weight: 500;
    font-weight: bold;
    border-width: 2px;
}