.flex-button-3icons{
    display: flex;
    align-items: center;
}
.contact-details-channels-icons{
    font-size: 22px;
}

.contact-details-channels-select-type{
    font-size: 18px;
    font-weight: 500;
   
}

.contact-details-channels-select-card{
    width: 600px;
}

.contact-details-channels-select-card-item{
    width: 570px;
    padding:6px ;
    border-bottom: 1px solid hsl(0, 0%, 89%);
}

.contact-details-channels-select-card-item:hover{
    cursor: pointer;
    background-color: aliceblue;
}