.htmltextbox-editor-wrapper{
    border-radius: 10px;
    outline-offset: 0px;
    position: relative;
}

.htmltextbox-editor-selector{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 5px;
    pointer-events: none;
}

.htmltextbox-editor-selector-active{
    outline:3px solid #006ce7;
    outline-offset: -4px;
}