.main-wrapper{
    max-width: 1000px;
    width: 100%;
}

.no-shadow {
    box-shadow: none !important;
}

.simple-wrapper {
    height: 400px !important;
}

.template-card {
    height: 400px;
    width: 300px;
    box-shadow: none !important;
    cursor: pointer;
    transition: .1s all ease-in-out;
}

.template-card:hover {
    outline: 1.5px solid #1b254b !important;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, .1) !important;
}

.title {
    font-size: 16px !important;
}

.tag {
    font-weight: bold;
}

.img-preview{
    height: 380px;
    width: 100%;
    object-fit: cover !important;
    overflow: hidden;
}

.preview{
    position: relative;
    height: 380px;
}

.preview-overlay{
    display: none;
    height: 100%;
    width: 100%;
    position: absolute !important;
    background-color: rgba(0,0,0,.2);
    border-radius: 5px;
    margin:-12px ;
}

.template-card:hover .preview-overlay{
    display: block;
}

.icon{
    font-size: 20px !important;
}

.margin-10{
    margin:10px ;
}

.flex-title{
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 6px ;
}

.template-name{
    font-size: 16px;
    font-weight: bold;
    color: #1b254b;
}

.stat-icon{
    font-size: 35px;
}

.stat-text{
    margin-right: 25px ;
}