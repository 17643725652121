.share-text{
    font-size: 18px;
}

.share-modal{
    height: 70vh !important;
    min-height: 70vh !important;
    max-height: 70vh !important;
}

.no-shadow{
    box-shadow: none !important;
}

.link-text{
    font-weight: 500;
}

.share-preview-wrapper{
    height: 100%;
}

.share-modal-preview{
    width: 100%;
    height: 450px;
    border-radius: 10px ;
    overflow-x: hidden !important;
}

.phone-frame-share{
    overflow-x: hidden !important;
    border: none;
    width: 100%;
    height: 450px;
    padding: 0;
    margin: 0;
    display: block;
}