.call-wrapper{
    max-height: 100vh ;
    height: 100vh;
    min-height: 100vh;
    background-color: #fcfcfc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.call-user-avatar{
    height: 200px;
    width: 200px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.called{
    background-color: #eb135b !important;
}

.caller{
    background-color: #7586e0;
}

.call-user-avatar .title{
    font-size: 50px !important;
    color: white;
}

.call-user-avatar .text{
    color: white;
}
.border-right{
    border-right: 2px solid #00000015;
}

.dissmis-button-wrapper{
    position: fixed;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 2px solid #00000023;
    box-shadow: 0px 10px 10px #00000015;
    height: 85px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:10px ;
    padding: 12px;
    border-radius: 40px;
}

.dissmis-call-button{
    width: 60px;
    height: 60px;
    font-size: 30px;
    border-radius: 70px;
    background: #e74a3b;
    outline: none !important;
    border:1px solid #e74a3b !important;
    color: #fff;
}

.accept-call-button{
    width: 60px;
    height: 60px;
    font-size: 30px !important;
    border-radius: 70px;
    background: #11c47c;
    outline: none !important;
    border:1px solid #11c47c !important;
    color: #fff;
}

.mic-call-button{
    width: 60px;
    height: 60px;
    font-size: 30px;
    border-radius: 70px;
    outline: none !important;
   }

.data-call-wrapper{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content:  start;
    gap: 6px;
    top: 10px;
    left: 10px;
    flex-wrap: wrap;
}

.rec-wrapper{
    background-color: #ff0101;
    padding: 10px;
    font-weight: bold;
    color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}
.audio-output-wrapper{
    background-color: #00000022;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.caller-operations-wrapper{
    position: fixed;
    top: 10px;
    right: 10px;
}