.contacts-home-icon{
    font-size: 32px;
}

.contacts-home-card{
    transition: .1s all ease;
    cursor: pointer;
}

.contacts-home-card:hover{
    outline: 5px solid #7586e0;
    color: #7586e0 !important;
}