.call-icon{
    font-size: 26px;
}

.call-error{
    color: #e74a3b;
}

.call-success{
    color: #1cc88a;
}


.call-icon-wrapper{
    width: 40px;
    height: 40px;
    border: 1px solid #f1f1f155;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
.rhap_container {
    box-shadow: none !important;
    border: 1px solid #0000000f !important;
    border-radius: 5px !important;
}*/

.rhap_button-clear {
    color: #1b254b !important;
}

.rhap_time {
    font-weight: 600 !important;
    color: #1b254b !important;
}

.rhap_progress-indicator, .rhap_progress-filled, .rhap_volume-indicator{
    background-color: #7586e0 !important;
}