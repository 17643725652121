.sender-work-wrapper{
    margin-top: 24px;
    overflow: hidden;
}

.sender-data-wrapper{
    height: 200px;
}

.warn-text{
    font-size: 12px;
    color: #cfcfcf;
}

.inbox-wrapper{
    height: 30px;
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
}

.sender-inbox-text{
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.sender-inbox-seperator{
    height: 2px;
    background-color: #cfcfcf;
    width: 100%;
}

.sender-sender-data-wrapper{
    padding: 0px 5px;
}

.sender-sender-data-name, .sender-sender-data-time{
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.sender-sender-data-subject{
    font-weight: bold;
    color: black;
}

.sender-sender-data-preview{
    color: #a1a1a1;
}

.margin-top{
    margin-top: 15px;
}

.sender-email{
    font-size: 16px;
}

.sender-nickname-domain{
    color: #919191;
}

.sender-email-wrapper{
    height: 50px;
}

.sender-confirmation{
    width: 7px;
    height: 7px;
    border-radius:7px;
}
.confirmed-sender{
    background-color: #1cc88a;
}
.not-confirmed-sender{
    background-color: #e74a3b;
}

.sender-preview-name{
    font-weight: 500;
}