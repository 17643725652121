.template-name{
    font-size: 18px !important;
    font-weight: 600 !important;
}

.template-updated{
    color:#a0a0a0 ;
}

.template-list-type-icon{
    font-size: 20px !important;
    color: white;
}

.template-type-wrapper{
    border-radius: 5px;
    height: 30px;
    width: 30px;
    background-color: #1b254b;
    display: flex;
    align-items: center;
    justify-content: center;
}