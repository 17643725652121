.item-card{
 height: 400px;
 width: 300px;   
 box-shadow: none !important;
 cursor: pointer;
 transition: .1s all ease-in-out;
}

.item-card:hover{
    border: 4px solid #1b254b !important;
    box-shadow: 0px 0px 25px rgba(0,0,0,.1) !important;
}

.item-wrapper{
    height: 375px !important;
    padding: 0px !important;
    margin:0px !important ;
}

.create-your-own-icon{
    font-size: 100px !important;
}

.text{
    font-size: 16px !important;
}