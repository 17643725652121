.notification-preview-main-wrapper{
    background-color: #fafafa;
    border-radius: 5px !important;
    width: 100%;
}

.notification-preview-wrapper{
    min-height: 130px;
    border: 2px solid #efefef;
    border-radius: 10px;
    padding: 10px;
}

.notification-preview-message-wrapper{
    text-align: center;
}

.notification-preview-message{
    font-size: 12px;
    color: #afafaf;
}

.notification-preview-wrapper-flex{
    min-height: 110px;
    margin-left: 12px;
}

.notification-preview-image{
    object-fit: cover !important;
    min-height: 50px;
    max-height: 75px;
    width: 100%;
    border-radius: 5px; 
}