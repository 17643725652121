.template-placeholder-tag{
    padding: 8px 15px;
    font-size: 17px;
    border-radius: 5px !important;
    background-color: #f9f9f9;
}

.create-advanced-template-wrapper{
    background-color: #f9f9f9 !important;
}

.create-advanced-template-inner-wrapper{
    background-color: #f9f9f9 !important;

}