.campaign-title{
    font-size: 18px !important;
    font-weight: 600 !important;
}

.campaign-recipients{
    color: #a0a0a0;
}

.campaign-performace-tag{
    border-radius: 5px !important;
}

.campaign-performace-tag-no-right-border{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

}

.campaign-options-icon{
    font-size: 20px !important;
}

.campaign-status-box{
    border: 1px solid;
}