.contact-title{
    font-size: 18px !important;
    font-weight: 600 !important;
}

.contact-created{
    color: #a0a0a0;
    font-weight: 500;
}

.contact-tag{
    background-color:#F0F0F0 ;
    padding:5px 10px;
    font-weight: 500;
    margin-left: 2px;
    border-radius: 5px;
}

.contact-tag-button{
    border-radius: 5px !important;
}

.contact-tag-add-button{
    background-color:#F0F0F0 ;
    border: none;
    margin-left: 6px;
}


.contact-tag-safe{
    font-weight: 600;
    border-width: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}