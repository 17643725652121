.sms-sender-list-default {
    background-color: #1cc88a;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 600 !important;
}

.order-phone-box{
    margin-top: 5px;
    cursor: pointer;
}

.order-phone-box:hover{
   outline: 5px solid #7586e0;
}

.order-phone-box-disabled{
    pointer-events: none !important;
    opacity: 0.8;
    background-color: rgba(0,0,0,.1);
}

.order-phone-box-disabled:hover{
    cursor:not-allowed;
}