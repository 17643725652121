.create-contact-error-card{
    border: 2px solid #e74a3b;
    background-color: #e74a3b22;
    border-radius: 10px;
    padding: 12px;
}

.create-contact-error-card-text{
    font-size: 16px;
    font-weight: 500;
    color: #e74a3b;
}