.email-campaign-details-wrapper{
    max-width: 1200px;
}

.email-campaign-details-audience{
    background-color: #f1f1f1;
    font-weight: 500;
    padding: 0px 10px;
    font-size: 16px;
    border-radius: 5px;
}
