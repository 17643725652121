.number-filters-card {
    box-shadow: none !important;
    background-color:hsl(0, 0%, 95%);
    padding: 0px 15px;
    border-radius: 15px;
    font-weight: 600;
    border: 1px solid hsl(0, 0%, 80%) !important;
}

.number-filters-card-wrapper{
    width: 100%;
    background-color: white;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    z-index: 99;
    border-bottom: 1px solid hsl(0, 0%, 85%);
}