.create-advanced-template-wrapper{
    border-bottom: 2px solid #000;
    height: 60px;
    width: 100%;
}

.create-advanced-template-inner-wrapper{
    border-bottom: 1px solid #d4d4d4;
    background: #fff;
    height: 60px
}

.margin-left-10{
    margin-left: 10px;
}

.bold{
    font-weight: 500 !important;
}

.create-template-options-text{
    margin: 5px;
    font-size: 16px !important;
    font-weight: 600;
}

.component-name-text {
    font-weight: 600 !important; 
    font-size: 16px;
}

.component-name-date-text {
    color: #a1a1a1;
}