.followup-title{
    font-size: 17px !important;
    font-weight: 500 !important;
}

.followup-createddate{
    color: #a0a0a0;
    font-weight: 500;
}

.tool-button-icon{
    font-size: 18px;
}

.followup-steps-tag{
    padding:5px 10px;
    font-weight: 500;
    font-size: 15px;
}