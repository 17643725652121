.kanban-column-border{
    border-color: hsl(0, 0%, 89%) !important;
}

.kanban-column-wrapper{
    background-color: #fff;
    position: sticky !important;
    height: 77px !important;
    top: 0px !important;
    z-Index: 1 !important;
    margin-Left: -10px !important;
    margin-Right: -10px !important;
    border-Top-Right-Radius: 10px !important;
    border-Top-Left-Radius: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}