.transcription-wrapper{
    max-height: 300px;
    overflow: auto;
    margin-top: 20px;
    width: 100%;
}

.active-trans{
    font-weight: 500 !important;
}

.trans-p{
    font-size: 16px;
}

.trans-card{
    min-width: 55% !important;
    max-width: 60% !important;
}

.trans-author-text{
    font-size: 12px !important;
    color: #a0a0a0;
}