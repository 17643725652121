.last-camps-camp-name{
    font-weight:600 ;
}
.last-camps-camp-data{
    color: #a0a0a0;
}

.send-tag-campaigns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:2px ;
    font-weight: 500;
}

.last-camps-camp-type{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    padding:0px 10px ;
}

.last-camps-camp-percentages{
    text-align: center;
    font-weight: 500;
    font-size: 18px;
}

.last-camps-camp-card{
    cursor: pointer;
    transition: .1s all ease;
    border: none !important;
    border-bottom: 2px solid #f0f0f0 !important;
    border-radius: 0px !important;
}

.last-camps-camp-card:hover{
    outline: 5px solid #7586e0;
    outline-offset: -5px;
    z-index: 999;
}

.p-10{
    padding:10px;
}