.deal-integraion-home-icon{
    font-size: 50px;
}

.deal-integration-card-title{
    font-size: 20px;
    font-weight: 600 !important;
}

.text-center{
    width: 100%;
    text-align: center !important;
}

.deal-integration-card{
    cursor: pointer;
    transition: .1s all ease;
}

.deal-integration-card:hover{
    outline: 5px solid #7586e0;
}