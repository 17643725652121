.text-center {
    text-align: center !important;
}

.browser {
    width: 750px;
    height: 550px;
    max-width: 750px;
    max-height: 550px;
    overflow: hidden;
    border: 2px solid #f0f0f0;
    border-radius: 5px;
    background-color: #fafafa;
}


.browser-header-icons {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 6px;
}

.browser-header {
    margin: 5px;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 6px;

}

.borwser-header-seperator {
    width: 100%;
    border-bottom: 2px solid #f0f0f0;
}

.borwser-search {
    width: 100%;
    height: 25px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.browser-icon {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: #f0f0f0;
}

.browser-frame {
    border: none;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: block;
}

.phone {
    background-color: #fafafa;
    width: 370px;
    height: 550px;
    max-width: 370px;
    max-height: 550px;
    overflow: hidden;
    border: 2px solid #f0f0f0;
    padding:5px;
    border-radius: 40px;
}

.phone-wrapper{
   margin-top: 30px;
   margin-bottom: 30px;
    width: 100%;
    height: 100%;
    border-radius: 30px ;
    overflow-x: hidden !important;
}
.phone-frame {
    overflow-x: hidden !important;
    border: none;
    width: 100%;
    height: 88%;
    padding: 0;
    margin: 0;
    display: block;
}