.margin-top{
    margin-top: 15px;
}
.small-margin-top{
    margin-top:5px ;
}

.subject-work-wrapper{
    margin-top: 24px;
}

.margin-right{
    margin-right: 12px;
}

.subject-icon{
    font-size: 18px !important;
    color: #7586e0;
}


.no-error-show{
    margin:0px !important ;
    margin-bottom: 0px !important;       
    padding: 0px !important;
}

.no-error-show .ant-form-item-explain-error{
    display: none !important;
}

.use-ai-button{
    font-weight: 500;
    color: #7586e0;
}

.emoji-icon{
    font-size: 22px !important;
}

.emoji-selector{
    position: absolute !important;
    z-index: 999999;
}

.subject-text-box-wrapper{
    border: 1px solid #e5e6eb;
    border-radius: 10px;
}